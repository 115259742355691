<template>
  <div>
    <el-image class="w100" :src="info.banner"></el-image>
    <div class="container">
      <el-row :gutter="20" type="flex" justify="center" class="flex-wrap solution-menu">
        <el-col v-for="(item, index) in info.content"
                :xs="5"
                :sm="5"
                :md="5"
                :lg="4"
                :xl="4">
          <div class="solution-flow">
            <a :href="`#images-` + index">
              <img :src="item.icon">
              <span class="solution-flow-title">{{item.title}}</span>
            </a>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="solution-list">
      <ul v-for="(list, i) in info.content" :key="`images-` + i" :id="`images-` + i">
        <li class="solution-item" v-for="img in list.images" :key="`solution-item-` + img" @click="goto(list.url)">
          <el-image :src="img" lazy>
            <!--<div slot="placeholder" class="image-slot">-->
            <!--  加载中<span class="dot">...</span>-->
            <!--</div>-->
            <el-skeleton slot="placeholder" style="width:400px" animated :count="3">
              <template slot="template">
                <el-skeleton-item
                    variant="image"
                    style="width: 400px; height: 267px;"
                />
              </template>
            </el-skeleton>
          </el-image>
          <!--<img :src="img">-->
        </li>
      </ul>
    </div>

    <el-popover
        placement="left"
        trigger="click"
        width="160"
        style="height: 160px;">
      <div class="wxCode">
        <h3>扫码联系专属客服</h3>
        <p>一对一为您答疑解惑</p>
        <el-image :src="options.customer_service"></el-image>
        <a>扫码立即联系</a>
        <p style="font-size: 12px">联系电话: 86-769-85613339</p>
      </div>

      <div class="customer-service"  slot="reference">
        <i class="el-icon-service"></i>
        <p>{{$t('page.console.menu.contact_us')}}</p>
      </div>
    </el-popover>
    <!--<share :title="info.title" :image="info.images ? info.banner : ''"></share>-->
  </div>
</template>


<script>
import {getSolution, getOption} from "@/api/os/admin";
// import share from '@/components/Share';

export default {
  // components: {
  //   share
  // },
  data() {
    return {
      info: {
        banner: '',
        content: []
      },
      options: []
    }
  },
  created() {
    this.get()
  },
  methods: {
    get() {
      if(this.$route.params.id) {
        getSolution({
          id: this.$route.params.id
        }).then( res => {
          this.info = res.data.data
        })

        getOption({
          name: 'page-home',
        }).then(res => {
          if(res.data.data) {
            this.options = res.data.data
          }
        })
      }
    },
    goto(url) {
      if(url) {
        window.location = url
      }


    }
  }
}
</script>

<style lang="less" scoped>
.solution-flow{
  font-size: 1.2rem;
  text-align: center;
  margin: 30px 0;
  &:hover{
    span{
      color: #00923F;
    }
  }
  img{
    width: 50%;
  }
  span{
    display: block;
  }
}
.el-image{
  overflow: unset;
  img{
    object-fit: cover;
  }
}

.solution-list{
  text-align: center;
}

.solution-item{
  display: inline-block;
  overflow: unset;
  width: 100%;
  max-width: 1200px;
  img{
    width: 100%;
    //height: 100%;
    //object-fit: cover;
    //vertical-align: top;
  }
}
.solution-menu{
  height: 116px;
  overflow: hidden;
}

.wxCode{
  text-align: center;
  p{
    color: #787878;
    margin: 10px auto;
  }
}
.customer-service{
  position: fixed;
  right: calc( (100% - 1200px) / 2 - 100px);
  bottom: 16%;
  z-index: 99;
  background: #ffffff;
  color: #00923F;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(20, 221, 107, 0.5);
  .el-icon-service{
    font-size: 50px;
  }
}

@media screen and (max-width: 768px){
  .solution-item{
    //height: 500px;
    height: auto;
  }
}
</style>